import gql from 'graphql-tag';

const fragments = {
    opApplication: gql`
      fragment opApplication on OpportunityApplication {
        average_score
        created_at
        current_status
        id
        managers {
            full_name
            id
            profile_photo
        }
        matchability
        permissions {
            can_be_rejected
        }
        person {
            first_name
            full_name
            id
            latest_academic_experience_backgrounds {
                id
                name
            }
            latest_academic_level {
                id
                name
            }
            latest_graduation_date
            person_profile {
                id
                nationalities {
                    id
                    name
                }
                skills {
                    constant_name
                    id
                    level
                    option
                }
            }
            profile_photo
            top_languages(amount: 4) {
                constant_id
                constant_name
                id
                level
            }
        }
        required_backgrounds_percentage
        required_skills_percentage
        status
      }
    `,
};

export const GetOpportunityApplications = gql`
    query GetOpportunityApplicationsQuery($page: Int, $per_page: Int, $filters: ApplicationFilter, $sort: String) {
        allOpportunityApplication(page: $page, per_page: $per_page, filters: $filters, sort: $sort) {
            paging {
                total_items,
                total_pages,
                current_page
            }
            data {
                ... opApplication
            }
        }
    }
${fragments.opApplication}
`

export const GetOpportunityApplication = gql`
    query GetApplicationQuery($id: ID) {
        getApplication(id: $id) {
            ... opApplication
        }
    }
    ${fragments.opApplication}
`

export const UpdateOpApplication = gql`
    mutation UpdateOpApplication($id: ID!, $filters: OpportunityApplicationInput) {
        updateApplication(id: $id, opportunity_application: $filters) {
            ... opApplication
        }
    }
    ${fragments.opApplication}
`

export const RejectApplication = gql`
    mutation RejectApplication($id: ID!) {
        rejectApplication(id: $id) {
            ... opApplication
        }
    }
${fragments.opApplication}
`

export const RejectApplications = gql`
    mutation RejectApplications($application_ids: [ Int ], $rejection_reason_id: Int) {
        bulkRejectApplications(application_ids: $application_ids, rejection_reason_id: $rejection_reason_id) {
            ... opApplication
        }
    }
${fragments.opApplication}
`

export const BulkCvDownload = gql`
    query BulkCvDownload($application_ids: [ ID ]) {
        bulkCvDownload(application_ids: $application_ids) {
            id
        }
    }
`

export const BulkCsvDownload = gql`
    query BulkCsvDownload($application_ids: [ ID ]) {
        applicationCsv(application_ids: $application_ids) {
            id
        }
    }
`
